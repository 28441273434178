
.container__navbar a{
    text-decoration:none;
    color: #fff;
    width: 60px;
    height: 60px;
}
.container__navbar{
    margin-top: 40px;
    margin-left: -80px;
    margin-right: 40px;
     color: #fff;
     position: fixed;
}

.container__navbar h3{
    margin-top: 0px;
    font-size: 14px ;
}

.navbar__home {
    display: flex;
    align-items: center;
    
}

.icon{
    margin-left: 10px;
    width: 25px;
    height: 25px;
}

.icon__1{
    margin-left: 10px;
    width: 35px;
    height: 35px;
    margin-left: 5px;
}

.navbar__prodgect{
    display: flex;
    align-items: center;
    
}

.navbar__connect{
    display: grid;
    gap: 10px;

    width: 60px;
}

.connect__icon{
    display: grid;
    gap: 15px;
    margin-left: 15px;
    margin-top: 500px;
}

.connect__icon a{
    width:15px ;
    height: 15px;
}
 .navbar__connect hr{
    width: 150px; 
    margin-top: 100px;
    margin-left: -52px;
    transform: rotate(90deg)
} 

