.header__container{

    margin-left: 150px;
    margin-top: 100px;
    }

    .my__info{
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .my__info img{
        padding-right: 100px;
        margin-left: 300px;
        width: 200px auto;
        height: 600px auto;
    }

    .my__info hr{
        border-color: black;
    }