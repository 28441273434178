.about__project{
    background: #3d3d3d;
    margin-top: 50px;
    margin-bottom: 100px;

    
}

.about__container{
    margin-top: 100px;
    padding-bottom: 300px;
}

.about__titul{
    margin-left: 750px;
    color: #fff;
}

.about__img img{
    width: 420px;
    height: 210px;
    padding-top: 40px;
    padding-left: 40px;
}

.about__project{
    margin-left: 80px;
    width: 500px;
    height: 400px;
}

.about__name{
    margin-top: 20px;
    margin-left: 220px;
    margin-bottom: 20px;
    font-size: 20px;
    color: aliceblue;
}


.about__project button{
    margin-left: 40px;
     border-radius: 20px;
    width: 420px;
    height: 40px;
    font-size: 20px;
    background-color: #fff;
}

.all__about{
    display: flex;
}